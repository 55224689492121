import React from 'react';
import { 
  Container, Card
} from 'react-bootstrap';

import * as Cards from '../Cards/Cards';
// import MyCanvas from './MyCanvas';

import './App.css';

const App = () => {
  return (
    <div className="App">
      {/* <MyCanvas /> */}
      <Container className='Container'>
        <Card className='Top'>
          <h1>Zokko</h1> 
        </Card>
        <Cards.Youtube />
        <Cards.Spotify />
        <Cards.Biography />
      </Container>

        <div id="foot">
        <a className="i" target="_blank" rel="noopener noreferrer" href="https://zokko.bandcamp.com/">BandCamp</a>
        <a className="i" target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/artist/756bsc3EfUreDxUc7ki6Pd?si=KV3Gz6tJT8itZNzgcoX1jQ" >Spotify</a>
        <a className="i" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/zokko__/">Instagram</a>
        <a className="i" target="_blank" rel="noopener noreferrer" href="https://music.apple.com/us/artist/zokko/1499142345">Apple</a>
        <a className="i" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCSkD4HX1C_xKl5EJOL3Gq4g">YouTube</a>
        <a className="i" target="_blank" rel="noopener noreferrer" href="https://soundcloud.com/zacharyjay">SoundCloud</a>
      </div> 
    </div>
  );
}

export default App;
